<template>
  <v-card class="mx-0">
    <template v-if="mode==='patient'">
      <template v-if="session.doctor != null">
        <v-card-title class="pb-2">
          <v-avatar size="50" class="elevation-25">
            <img :src="session.doctor.avatar" alt="">
          </v-avatar>
          <div class="pr-2">
            <template v-if="!$vuetify.rtl">
              <span class="text-large">{{ session.doctor.en_name }}</span>
            </template>
            <template v-else>
              <span class="text-large">{{ session.doctor.full_name }}</span>
            </template>

            <div v-if="session.diff_time">
              <template v-if="$vuetify.rtl">
                     <span class="primary--text">
                  {{ session.diff_time }}
                </span>
              </template>
              <template v-else>
                     <span class="primary--text">
                  {{ session.en_diff_time }}
                </span>
              </template>

            </div>
            <div v-else>
              <template>
                <span class="text-normal primary--text">
                    {{$t('$vuetify.timeUnknown.timeIsUnknown')}}
                </span>
              </template>
            </div>
          </div>
        </v-card-title>
      </template>
      <template v-else>
        <v-card-title class="pb-2">
          <v-avatar size="50" class="elevation-25">
            <img :src="require('./../../assets/avatar-default.png')" alt="">
          </v-avatar>
          <div class="pr-2">
            <span class="text-large red--text">{{$t('$vuetify.sessionCard.doctorUnknown')}}</span>
            <div>
              <template>
                <span class="text-normal primary--text">
                    {{$t('$vuetify.sessionCard.timeUnknown')}}
                </span>
              </template>
            </div>
          </div>
        </v-card-title>
      </template>
    </template>
    <template v-else>
      <template>
        <v-card-title class="pb-2">
          <v-avatar size="50" class="elevation-25">
            <img :src="require('./../../assets/avatar-default.png')" alt="">
          </v-avatar>
          <div class="pr-2">
            <span class="text-large">{{ session.user.full_name }}</span>
            <div>
              <template>
                <span class="text-normal primary--text">
                  {{ session.diff_time }}
                </span>
              </template>
              <!--<template v-else-if="session.state == 'used'">-->
              <!--<v-chip small class="success">-->
              <!--برگزار شده-->
              <!--</v-chip>-->
              <!--</template>-->


            </div>
          </div>
        </v-card-title>
        <div class="mx-2">

        </div>
      </template>
    </template>
    <v-divider></v-divider>
    <v-card-text class="pt-2 pb-1">
      <div v-if="mode==='patient'">
        {{$t('$vuetify.sessionCard.cycle')}}
        :
        {{ session.cycle.heading }}
      </div>
      <template v-else>
        <v-expansion-panels class="pb-2">
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('$vuetify.sessionCard.cycle')}}
              :

              {{ $vuetify.rtl ? session.cycle.heading : showCycleHeadingInEn(session.cycle.heading) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ session.cycle.problem_description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-card-text>
    <v-card-text class="pt-0 pb-1">
      {{$t('$vuetify.sessionCard.fixed_time')}}
      :
      <span v-if="session.fixed_date != null">
            {{ session.fixed_date }}
            {{ session.fixed_time }}
          </span>
      <span v-else> {{$t('$vuetify.sessionCard.unknown')}}</span>
    </v-card-text>
    <v-card-text class="pt-0 pb-1" v-if="session.timezone!=null">
      {{$t('$vuetify.sessionCard.timezone')}}
       :
      <template>
        {{ session.timezone }}
      </template>

    </v-card-text>
    <v-card-text class="pt-0 pb-1">
      {{$t('$vuetify.sessionCard.status')}}
      :
      <v-chip small>
        {{   resolveSessionState(session.state) }}
      </v-chip>
    </v-card-text>
    <v-card-text class="pa-0 my-2" v-if="session.state==='doctor_assigned' && selectTime">
      <SessionSetTime @updateSessions="updateSessions" :session="session"></SessionSetTime>
    </v-card-text>
    <!--<v-card-text class="pa-0 my-2" v-else>-->
    <!--<v-card class="elevation-0">-->
    <!--<v-card-text class="my-0 py-0 px-3 elevation-25">-->
    <!--<v-expansion-panels disabled class="pa-0 ma-0 ">-->
    <!--<v-expansion-panel aria-expanded="false">-->
    <!--<v-expansion-panel-header class="text&#45;&#45;secondary">گام بعدی ندارد</v-expansion-panel-header>-->
    <!--<v-expansion-panel-content></v-expansion-panel-content>-->
    <!--</v-expansion-panel>-->
    <!--</v-expansion-panels>-->
    <!--</v-card-text>-->
    <!--</v-card>-->
    <!--</v-card-text>-->
    <template v-if="mode==='patient'">
      <v-card-title class="ma-0 px-3 pt-3">
        <template v-if="session.doctor != null">
          <!--<v-btn rounded block @click="showQNA" small-->
          <!--class="info white&#45;&#45;text" text>پرسشنامه-->
          <!--</v-btn>-->
        </template>
        <template v-else>
          <!--<v-btn rounded block disabled @click="showQNA" small-->
          <!--class="outlined-info info&#45;&#45;text" text>پرسشنامه موجود نیست-->
          <!--</v-btn>-->
        </template>
      </v-card-title>
    </template>
    <v-card-actions>
      <v-row dense justify="center" align="center">
        <v-col cols="6">
          <v-btn block rounded class="warning" @click="joinSession" :disabled="!session.can_enter"
                 :loading="loading">
            {{$t('$vuetify.sessionCard.enterSession')}}
          </v-btn>
        </v-col>
        <v-col cols="6" v-if="factor">
          <v-btn block rounded class="primary" @click="getFactor(session)"
                 :disabled="session.state != 'used'">
            {{$t('$vuetify.sessionCard.receiveFactor')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import {EventBus} from "@/event-bus";
import SessionSetTime from "@/components/sessions/SessionSetTime";

export default {
  props: {
    session: {},
    factor: {
      default: true
    },
    timezone: {
      default: null
    },
    mode: {
      default: 'patient'
    },
    selectTime: {
      default: true
    }

  },
  components: {SessionSetTime},
  data() {
    return {
      sessionAppLink: null,
      loading: false
    }
  },
  computed: {
    sessionStateClass() {
      let val = '';
      if (this.session.state == 'session_requested') {
        val = 'disabled'
      } else if (this.session.state == 'doctor_assigned') {
        val = ''
      } else if (this.session.state == 'used') {
        val = 'success'
      } else if (this.session.state == 'date_fixed') {
        val = 'info'
      }
      return val;

    }
  },
  methods: {
    // showQNA() {
    //     window.cl3CQobtr('porsline-popup-iframe');
    //     window.showPopUp();
    // },
    updateSessions() {
      this.$emit('updateSessions');
    },
    getTimes() {
      this.requesting = true;
      this.$store.dispatch('getSessionRequestTimes', {id: this.currentRequestSession.id}).then((resp) => {
        this.doctorTimes = resp.data.doctorTimes;
        this.requesting = false;
      }).finally(() => {
        this.requesting = false;
      })
    },
    joinSession() {
      if (this.session.link == null) {
        this.loading = true;
        if (this.mode == 'patient') {
          this.$store.dispatch('clientJoinSession', this.session.id).then((resp) => {
            this.$store.dispatch('setCallInfo',resp.data)
            this.$router.push({name:'videoCall'})
          }).finally(() => {
            this.loading = false;
          })
        } else {
          this.$store.dispatch('doctorJoinSession', this.session.id).then((resp) => {
            this.$store.dispatch('setCallInfo',resp.data)
            this.$router.push({name:'videoCall'})
          }).finally(() => {
            this.loading = false;
          })
        }

      } else {
        this.sessionAppLink = this.session.link;
        window.location = this.session.link;
      }
    },
    getFactor(item) {
      EventBus.$emit('setLoading', true);
      this.$store.dispatch('getFactor', item.id).finally(() => {
        EventBus.$emit('setLoading', false);
      })
    },
    startSession(session) {
      this.$router.push({name: 'startsession', params: {sessionId: session.id}})
    },
    resolveSessionState(status){
      switch (status) {
        case 'session_requested':
          return this.$t('$vuetify.sessionCard.statuses[0]');
        case 'doctor_assigned':
          return this.$t('$vuetify.sessionCard.statuses[1]');
        case 'date_fixed':
          return this.$t('$vuetify.sessionCard.statuses[2]');
        case 'used':
          return this.$t('$vuetify.sessionCard.statuses[3]');
      }
    },
    showCycleHeadingInEn(input){
      switch (input){
        case 'فردی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[0]')
          break;
        case 'زوج':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[1]')
          break;
        case 'خانواده':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[2]')
          break;
        case 'کودک و نوجوان':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[3]')
          break;
        case 'سکس تراپی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[4]')
          break;
        case 'روانپزشکی':
          input =  this.$t('$vuetify.clientStepper.addCycleModal.categories[5]')
          break;
      }
      return input
    },

  },
  // filters: {
  //   resolveSessionState(input) {
  //     switch (input) {
  //       case 'session_requested':
  //         return this.$t('$vuetify.sessionCard.statuses[0]');
  //       case 'doctor_assigned':
  //         return 'در انتظار انتخاب زمان از طرف شما';
  //       case 'date_fixed':
  //         return ;
  //       case 'used':
  //         return 'برگزار شده';
  //     }
  //   }
  // },

}
</script>

<style scoped>

</style>