<template>
    <v-card class="elevation-0">
        <v-card-text class="my-0 py-0 px-3 elevation-0" v-if="session.state === 'doctor_assigned'">
            <v-expansion-panels v-model="currentRequest" class="pa-0 ma-0 ">
                <v-expansion-panel aria-expanded="false">
                    <v-expansion-panel-header v-slot="{ open }">
                        <v-container class="pa-0 ma-0">
                            <!--<v-row>-->
                            <!--<v-col cols="12">وضعیت : {{sessionStateText(session)}}</v-col>-->
                            <!--</v-row>-->
                            <v-row no-gutters>
                                <!--<v-col cols="12" sm="4">موضوع : {{session.cycle.heading}}</v-col>-->
                                <v-col
                                        cols="12"
                                        class="text--secondary"
                                >
                                    <v-fade-transition leave-absolute>
                                        <template>
                                    <span v-if="session.state==='doctor_assigned'">
                                        {{$t('$vuetify.setSession.title')}}
                                    </span>
                                            <span v-if="session.state==='session_requested'">
                                        {{$t('$vuetify.setSession.loading')}}
                                    </span>
                                        </template>
                                    </v-fade-transition>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <!--<v-row no-gutters class="mt-3">-->
                        <!--<v-col cols="12" class="text-center" v-if="session.doctor!=null">-->
                        <!--<span v-if="session.state=='doctor_assigned'">-->

                        <!--<v-avatar-->
                        <!--size="100"-->
                        <!--class="elevation-3"-->
                        <!--&gt;-->
                        <!--<v-img v-if="session.doctor.avatar!=null"-->
                        <!--:src="session.doctor.avatar"></v-img>-->
                        <!--</v-avatar>-->
                        <!--<div class="ma-2 font-weight-bold title">-->
                        <!--<span>{{session.doctor.full_name}}</span>-->
                        <!--</div>-->
                        <!--</span>-->
                        <!--</v-col>-->

                        <!--</v-row>-->
                        <v-card :loading="requesting" class="elevation-0 ma-3 pa-3 mt-0 pt-0">

                            <template v-if="requesting">
                                <v-row class="text-center">
                                    <v-col class="text-center">
                                        <span>
                                          {{$t('$vuetify.setSession.loading')}}
                                        </span>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-row align="center" justify="center" v-if="doctorTimes!=null">
                                    <v-col cols="12" class="text-center">
                                        <template v-if="Object.keys(doctorTimes).length">
                                          {{$t('$vuetify.setSession.titleSelect')}}
                                          <DoctorTimes @timeSelected="selectTime"
                                                         :doctorTimes="doctorTimes"></DoctorTimes>
                                        </template>
                                        <template v-else>
                                          {{$t('$vuetify.setSession.emptyState')}}
                                        </template>

                                    </v-col>
                                </v-row>
                            </template>


                        </v-card>

                        <v-card-actions>

                            <v-spacer></v-spacer>
                            <v-btn

                                    class=" elevation-0 warning white--text"
                                    @click="currentRequest = null"
                            >
                              {{$t('$vuetify.clientStepper.close')}}
                            </v-btn>
                            <v-btn
                                    class=" elevation-0 primary white--text"
                                    @click="saveTimeForRequest(session)"

                            >
                              {{$t('$vuetify.setSession.save')}}
                            </v-btn>
                        </v-card-actions>
                    </v-expansion-panel-content>

                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>

<script>
    /*eslint-disable*/

    import {mapGetters} from 'vuex'
    import moment from 'moment-jalaali';
    import {EventBus} from "@/event-bus";
    import DoctorTimes from '@/components/clients/DoctorTimes';


    export default {
        props: ['session'],
        created() {
            this.handleClick();
        },
        components: {
            DoctorTimes
        },
        data() {
            return {
                selectedTime: null,
                requesting: false,
                currentRequestSession: null,
                currentRequest: 0,
                doctorTimes: {},
                search: '',
                headers: [
                    {text: 'وضعیت', value: 'state', sortable: true, align: 'right'},
                    {text: 'گام بعدی', value: 'todo', sortable: true, align: 'center'},
                    {
                        text: 'مشاور',
                        align: 'start',
                        sortable: false,
                        value: 'doctor.full_name',
                    },
                    {text: 'عملیات', value: 'action', sortable: false, align: 'center'},
                ],
                page: 1,
                total: 1,
                sessions: [],
                nextSession: {
                    dayNum: "",
                    jdayNum: "",
                    monthName: "",
                    jmonthName: "",
                    jdayName: "",
                    time: "",
                    dateTime: "",
                    id: "",
                    consult: "",
                }
            }
        },
        computed: {

            ...mapGetters({
                // sessions: 'sessions',
                // nextSession: 'nextSession',
                activeCycle: 'getActiveCycle'
            }),
            nextSessionLabel() {
                return this.$vuetify.lang.t('$vuetify.nextSessionLabel');
            },
            sessionsListLabel() {
                return this.$vuetify.lang.t('$vuetify.sessionsListLabel');
            },

        },

        methods: {
            handleClick() {
                this.doctorTimes = {};
                this.selectedTime = null;
                if (this.currentRequest != undefined) {
                    this.currentRequestSession = this.session;
                    this.getTimes();
                } else {
                    this.currentRequestSession = null;
                }
            },
            saveTimeForRequest(session) {

                if (this.selectedTime == null || this.selectedTime.length == 0) {
                    EventBus.$emit('notify', 'red', undefined,  this.$t('$vuetify.Notify.ExperssComponent.selectOne'));
                    return;
                }

                let payload = {
                    time: this.selectedTime
                };

                this.$store.dispatch('selectSessionRequestTime', {id: session.id, payload}).then((resp) => {
                    console.log(resp);
                    EventBus.$emit('notify', 'green', undefined,  this.$t('$vuetify.Notify.successfully'));
                    // this.getSessionRequests();
                    this.$emit('updateSessions');
                })

            },
            selectTime(hour) {
                this.selectedTime = hour[0];
            },
            getTimeOptionDate(time) {
                return moment(time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            },
            getTimes() {
                this.requesting = true;
                this.$store.dispatch('getSessionRequestTimes', {id: this.currentRequestSession.id}).then((resp) => {
                    this.doctorTimes = resp.data.doctorTimes;
                    this.requesting = false;
                }).finally(() => {
                    this.requesting = false;
                })
            },
            sessionStateText(session) {

                switch (session.state) {
                    case 'doctor_assigned':
                        return 'مشاور شما انتخاب شده است';
                    case 'session_requested':
                        return 'منتظر انتخاب مشاور باشید';
                }
            },

            getSessionRequests() {
                let options = {
                    state: 'doctor_assigned|session_requested',
                    orderBy: "session_state:desc"
                };

                if (this.activeCycle != null) {
                    options.cycle_id = this.activeCycle.id;
                }

                this.$store.dispatch('getSessions', {page: this.page, options}).then((data) => {

                    this.total = data.data.pagination.total_pages;
                    // this.sessions = data.data.sessions;
                    // this.total = data.paginate.totalPages
                })
            },


        },
        watch: {

            currentRequest() {
                this.handleClick();
            },
            page() {
                // this.getSessionRequests();
                // this.$vuetify.goTo(this.$refs.sessions.offsetTop);

            }
        }
    }
</script>

<style>
    /*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):not(:first-child) {*/
    /*border-radius: 15px;*/
    /*border-left: 8px solid #43378C;*/
    /*}*/

    /*#sessions .v-card:not(.v-sheet--tile):not(.v-card--shaped):first-child {*/
    /*border-radius: 15px;*/
    /*border-left: 8px solid #86C23B;*/
    /*}*/
</style>

<style scoped>
    /*.green-border {*/
    /*border: 5px solid #86C23B;*/
    /*}*/

</style>